import { gql } from "@apollo/client";

export const SIGNUP_MUTATION = gql`
  mutation SignUpUser($userData: UserInput!) {
    signupUser(userData: $userData) {
      errors
      user {
        authProvider
        email
        firstName
        id
        isActive
        isSingedUp
        lastLogin
        lastName
        phoneNumber
        profilePicture
        username
      }
    }
  }
`;

export const EMAIL_VERIFICATON = gql`
  mutation Mutation($input: VerifyOTPInput!) {
    verifyOTP(input: $input) {
      success
    }
  }
`;

export const GENERATE_OTP = gql`
  mutation GenerateOTP($input: GenerateOTPInput!) {
    generateOTP(input: $input) {
      success
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($input: VerifyOTPInput!) {
    verifyOTP(input: $input) {
      success
    }
  }
`;

export const REQUEST_AUTH_CHALLENGE = gql`
  mutation RequestAuthChallenge {
    requestAuthChallenge {
      authCode
    }
  }
`;

export const REQUEST_TOKEN = gql`
  mutation RequestToken($input: RequestTokenInput!) {
    requestToken(input: $input) {
      token
      restToken
    }
  }
`;

export const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestTokenMutation($input: RequestTokenInput!) {
    requestToken(input: $input) {
      nounceToken
      token
      restToken
    }
  }
`;
