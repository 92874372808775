import { gql } from '@apollo/client';

export const LOAD_USERS = gql`
  query {
    getAllUsers {
      id
      firstName
      email
      password
    }
  }
`;

export const LEAD_EXISTS = gql`
  query LeadExists($phoneNumber: String!) {
    leadExists(phoneNumber: $phoneNumber) {
      isExists
    }
  }
`;
