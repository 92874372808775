import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  // GoogleAuthProvider,
  // RecaptchaVerifier,
  // signInWithPhoneNumber,
  // signInWithPopup
} from "firebase/auth";
import { auth } from "../firebase.config";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [userDetail, setUserDetail] = useState();
  const [isUserRegistered, setIsUserRegisterd] = useState(false);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  const redirectToAppStore = () => {
    window.location.href = process.env.REACT_APP_APP_STORE_LINK
    // window.open(process.env.REACT_APP_APP_STORE_LINK, '_blank');
};

const redirectToPlayStore = () => {
    window.location.href = process.env.REACT_APP_PLAY_STORE_LINK;
    // window.open(process.env.REACT_APP_PLAY_STORE_LINK, '_blank');
};

  // function signUpWithEmail(firstName, lastName, email, password) {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // }

  function logOut() {
    return signOut(auth);
  }

  // function googleSignIn() {
  //   const googleAuthProvider = new GoogleAuthProvider();
  //   return signInWithPopup(auth, googleAuthProvider);
  // }

  // function setUpRecaptha(number) {

  //   const recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {size: "invisible"},
  //     auth
  //   );
  //   recaptchaVerifier.render();
  //   return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  // }

  const updateUserDetail = (userDetail) => {
    setUserDetail(userDetail)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // console.log("Auth", currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      // value={{ user, logIn, signUp, logOut, setUser, setUpRecaptha }}
      value={{ user, logIn, signUp, logOut, setUser,updateUserDetail, userDetail, isUserRegistered, setIsUserRegisterd, redirectToAppStore, redirectToPlayStore }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}