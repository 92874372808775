import { encryptionSecretKey } from '../Constants';
import CryptoJS from 'crypto-js';

export function isValidURL(str) {
  try {
    const url = new URL(str);
    return { success: true, data: url };
  } catch (error) {
    return { success: false, error };
  }
}

// export function redirectToExternalUrl(url, authToken) {
//   const redirectUrl = `${url}?authToken=${authToken}&source=web`;

//   window.location.href = redirectUrl;
// }

export const encryptionAES = (inputValue, secretKey = encryptionSecretKey.secretKey) => {
  const encrypted = CryptoJS.AES.encrypt(inputValue, secretKey);
  return encrypted.toString();
};

export const decryptionAES = (encryptedValue, secretKey = encryptionSecretKey.secretKey) => {
  if (encryptedValue) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      // console.log('decrypted :', decrypted);
      return { success: true, data: decrypted };
    } catch (error) {
      return { success: false, error };
    }
  }
};

// export const handleDecrypt = async (encryptedValue) => {
//   console.log('encryptedValue - handle function :', encryptedValue);
//   if (encryptedValue) {
//     const result = await decryptionAES(encryptedValue);
//     if (result.success) {
//       return result;
//     } else {
//       return (window.location.href = document.referrer);
//     }
//   }
// };

export function redirectToExternalUrl(url, authToken, encryption = false, paramName = 'authToken') {
  let redirectUrl;
  if (encryption) {
    redirectUrl = `${url}?${paramName}=${encodeURIComponent(encryptionAES(authToken))}&source=web`;
  } else {
    redirectUrl = `${url}?${paramName}=${authToken}&source=web`;
  }

  return (window.location.href = redirectUrl);
}

// export function formatPhoneNumber(phoneNumber) {
//   const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

//   if (numericPhoneNumber.length !== 10) {
//     return "Invalid phone number";
//   }

//   const formattedPhoneNumber = `(${numericPhoneNumber.slice(
//     0,
//     3
//   )}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6)}`;

//   return formattedPhoneNumber;
// }

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length < 11) {
    return 'Invalid phone number';
  }

  const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
  const formattedPhoneNumber = `${countryCode} (${phoneNumber.slice(-10, -7)}) ${phoneNumber.slice(
    -7,
    -4
  )}-${phoneNumber.slice(-4)}`;

  return formattedPhoneNumber;
}
