import axios from "axios";
import { API_BASE_URL } from "../constants";
import { APIRoutes } from "../route-constants";

export const LoggerService = {
  /**
   * Use to create error logs.
   * @param url:string
   * @param method:string
   * @param headers: String
   * @param payload: String
   * @param response_status: String
   * @param response_headers: Array
   * @param response_body: JSON obj
   * @param client: String
   * @param client_os: String
   * @returns success
   */
  POST: async function (loggerData) {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.LOGGER}`, loggerData))
      .data;
  },
};
