import { Routes, Route } from 'react-router-dom';
import SignupPage from './pages/SignupPage';
import { Toaster } from 'react-hot-toast';
import PageNotFound from './pages/PageNotFound';
import RedirectToAppStoreAndPlayStore from './components/RedirectToAppStoreAndPlayStore';
import LeadNotFoundPage from './components/MainScreen/LeadNotFoundPage';

function App() {
  return (
    <>
      <Toaster toastOptions={{ duration: 6000 }} />
      <Routes>
        <Route path="/" element={<SignupPage />} />
        <Route path="/lead-not-found" element={<LeadNotFoundPage />} />
        <Route path="/redirect" element={<RedirectToAppStoreAndPlayStore />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
