import React, { useEffect, useState } from 'react';
import './MainScreen.scss';
import daylightLog from '../../assets/images/customer-portal-logo.png';
import ufo_img from '../../assets/images/ufoImg.png';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../Button/Button';
import { decryptionAES, isValidURL } from '../../utils/util';
import DayLightLogo from '../../resources/images/DayLightLogo';

const LeadNotFoundPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const urlFrom = params.get('urlFrom');
  //   const [isURLValid, setIsURLValid] = useState();
  const [redirectionUrl, setRedirectionUrl] = useState();

  useEffect(() => {
    // if (urlFrom) {
    //   const url = new URL(urlFrom);
    //   const newUrl = `${url.origin}/newcustomer/`;
    //   console.log('Redirect URL:', newUrl);

    //   console.log('newUrl :', newUrl);
    // }

    const asyncFunction = async () => {
      if (!urlFrom) {
        // console.error('urlFrom is undefined or empty');
        return;
      }

      try {
        const decryptedResult = await decryptionAES(urlFrom);

        if (decryptedResult.success) {
          const isURLValid = isValidURL(decryptedResult.data);
          if (isURLValid.success) {
            // console.log('Valid URL:', isURLValid.data);
            const url = new URL(decryptedResult.data);
            const newUrl = `${url.origin}/newcustomer/`;

            setRedirectionUrl(newUrl);
          } else {
            console.error('Invalid URL:', isURLValid.error);
            navigate('/404');
          }
        } else {
          console.error('Decryption failed:', decryptedResult.error);
          navigate('/404');
        }
      } catch (error) {
        console.error('Error:', error);
        navigate('/404');
      }
    };

    asyncFunction();
  }, [navigate, urlFrom]);

  const handleStartedButton = () => {
    redirectionUrl ? (window.location.href = redirectionUrl) : navigate('/404');
  };

  return (
    <section className="customer-portal">
      <div className="flex justify-center items-start sm:flex-row flex-col sm:h-screen h-full">
        <div
          className={`!bg-primary sm:px-0 sm:py-[40px] p-[45px] text-center sm:w-1/2 w-full sm:h-full h-[390px] flex justify-around items-center flex-col`}>
          <div>
            {/* <img
              className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto"
              src={daylightLog}
              alt=""
            /> */}
            <div className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto">
              <DayLightLogo />
            </div>
          </div>

          <div>
            <img className="sm:block hidden max-w[790px] w-[80%]" src={ufo_img} alt="" />
            <img className="sm:hidden block max-w[390px] mt-[30px] mx-auto" src={ufo_img} alt="" />
          </div>
        </div>
        <div className="sm:w-1/2 sm:m-auto mt-[46px]  mx-[48px]">
          <div className="sm:w-[80%] w-full mb-[20px] mx-auto">
            <div>
              <h3 className="text-tertiary sm:text-[32px] text-[28px] sm:leading-[40px] leading-[36px] mb-[10px]">
                Oops! No account found.
              </h3>
              <p className="text-black sm:text-[16px] text-[14px] sm:leading-[24px] leading-[20px] mb-[40px]">
                It looks like you don’t have an account with us, try going through our questionnaire
                to find out which energy upgrades you qualify for.
              </p>

              <Button
                type="button"
                onClick={handleStartedButton}
                size="large"
                // className="!mt-[40px] !py-[12px] sm:!min-w-[207px] min-w-full h-[50px] font-aeonik-bold !text-primary !rounded-full !bg-[#F1D972] hover:!bg-[#D3B01A] disabled:!bg-gray-200 disabled:cursor-none"
                className="!mt-[40px] !py-[12px] sm:!min-w-[207px] min-w-full h-[50px] font-aeonik-bold !rounded-full !bg-tertiary hover:!opacity-75 hover:scale-105 disabled:!bg-gray-200 disabled:cursor-none"
                theme="primary">
                Get started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadNotFoundPage;
